import React from 'react'

import { graphql } from 'gatsby'

import SEO from '../components/seo'

import Layout from '../components/layout'

import BlogListVertical from '../components/blog-list-vertical'

export default ({ data, pageContext }) => {
    let page = data.allWordpressPage.edges[0].node

    return (
        <Layout
            breadcrumb={[{ title: 'Home', link: '/' }, { title: 'Blog' }]}
            marketTicker
        >
            <SEO
                title={page.yoast_meta.yoast_wpseo_title}
                description={page.yoast_meta.yoast_wpseo_metadesc}
            />
            <BlogListVertical data={data} pageContext={pageContext} />
        </Layout>
    )
}
export const query = graphql`
    query($skip: Int!, $limit: Int!) {
        allWordpressPage(filter: { slug: { eq: "blog" } }) {
            edges {
                node {
                    yoast_meta {
                        yoast_wpseo_title
                        yoast_wpseo_metadesc
                    }
                }
            }
        }
        allWordpressPost(
            limit: $limit
            skip: $skip
            sort: { fields: date, order: DESC }
        ) {
            edges {
                node {
                    slug
                    title
                    excerpt
                    date
                    categories {
                        name
                    }
                    tags {
                        name
                    }
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 900) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
