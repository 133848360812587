import React from 'react'

import { TiArrowRight } from 'react-icons/ti'

import { Link } from 'gatsby'

import Button from '../button'

import Image from 'gatsby-image'

import moment from 'moment'

import styles from './style.module.scss'

import cn from 'classnames'

export default ({ data, pageContext }) => {
    const { numPages, currentPage } = pageContext
    const showButtons = 2

    return (
        <div className="wrapper">
            <div className="row">
                <div className="col-xs-12">
                    <div className={styles.blogList}>
                        <h1>Our Blog</h1>
                        <ul className={styles.blogs}>
                            {data.allWordpressPost.edges.map(edge => (
                                <li key={edge.node.title}>
                                    {edge.node.featured_media &&
                                    edge.node.featured_media.localFile &&
                                    edge.node.featured_media.localFile
                                        .childImageSharp ? (
                                        <Image
                                            fluid={
                                                edge.node.featured_media
                                                    .localFile.childImageSharp
                                                    .fluid
                                            }
                                            className={styles.image}
                                        />
                                    ) : null}
                                    <div className={styles.body}>
                                        <span className={styles.category}>
                                            {edge.node.categories
                                                .map(c => c.name)
                                                .join(', ')}
                                        </span>
                                        <h3
                                            dangerouslySetInnerHTML={{
                                                __html: edge.node.title
                                            }}
                                        />
                                        <div className={styles.bottomRow}>
                                            <span className={styles.date}>
                                                {moment(edge.node.date).format(
                                                    'MMMM Do Y'
                                                )}
                                            </span>
                                            <span className={styles.tags}>
                                                {edge.node.tags &&
                                                edge.node.tags.length
                                                    ? edge.node.tags
                                                          .map(c => c.name)
                                                          .join(', ')
                                                    : null}
                                            </span>
                                        </div>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: edge.node.excerpt
                                            }}
                                        />
                                        <Link
                                            to={`/blog/${edge.node.slug}`}
                                            className={styles.link}
                                        >
                                            Read more <TiArrowRight size={25} />
                                        </Link>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <ul className={styles.pagination}>
                            <li>
                                <Button link={`/blog`}>First</Button>
                            </li>
                            {Array.from({ length: numPages })
                                .map((_, i) => i)
                                .slice(
                                    currentPage - showButtons - 1 < 0
                                        ? 0
                                        : currentPage - showButtons - 1,
                                    currentPage + showButtons > numPages
                                        ? numPages
                                        : currentPage + showButtons
                                )
                                .map(i => {
                                    return (
                                        <li key={i}>
                                            <Button
                                                link={
                                                    i === 0
                                                        ? '/blog'
                                                        : `/blog/${i + 1}`
                                                }
                                                className={cn({
                                                    [styles.active]:
                                                        i + 1 === currentPage
                                                })}
                                            >
                                                {i + 1}
                                            </Button>
                                        </li>
                                    )
                                })}
                            <li>
                                <Button link={`/blog/${numPages}`}>Last</Button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
